import styled from 'styled-components';

const ContentWrapper = styled.div`
  z-index: 1;
  position: relative;
  max-width: 1200px;
  margin: auto;
  text-align: center;
  color: ${(props) => props.theme.colors.brown};

  h1 {
    font-family: ${(props) => props.theme.fonts.grosa};
    font-weight: 300;
    font-size: 85px;
    line-height: 90px;
    letter-spacing: -0.03em;
    padding-bottom: 35px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 40px;
      line-height: 55px;
      padding-bottom: 15px;
    }
  }

  h2 {
    font-family: ${(props) => props.theme.fonts.nantes_book};
    font-weight: 500;
    font-style: italic;
    font-weight: normal;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.02em;
    padding-bottom: 18px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 22px;
      line-height: 34px;
    }
  }

  p {
    font-family: ${(props) => props.theme.fonts.grosa};
    font-size: 18px;
    line-height: 30px;
  }
`;

const SectionWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export { ContentWrapper, SectionWrapper };
