import styled from 'styled-components';

const MainWrapper = styled.div`
  min-height: calc(100vh - 175px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;
`;

export default MainWrapper;
