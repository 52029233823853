import styled from 'styled-components';

const ImageWrapper = styled.div`
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`;

export default ImageWrapper;
